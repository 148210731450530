import { Content } from '@prismicio/client';
import {
  JSXMapSerializer,
  PrismicRichText,
  SliceComponentProps,
} from '@prismicio/react';
import Container from '@/components/Unknown/Container';
import { ArrowRightV2 } from '@/components/Unknown/Icons';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicNextLink } from '@/components/Internal/Button';

/**
 * Props for `ImageWithText`.
 */
export type ImageWithTextProps =
  SliceComponentProps<Content.ImageWithTextSlice>;

/**
 * Component for "ImageWithText" Slices.
 */

const component: JSXMapSerializer = {
  heading2: ({ children }) => (
    <h2 className="text-pretty text-arc-4xl font-bold sm:~lg:~text-arc-7xl/arc-9xl">
      {children}
    </h2>
  ),
  paragraph: ({ children }) => (
    <p className="mx-auto max-w-prose text-pretty font-normal ~lg:~text-arc-base/arc-lg">
      {children}
    </p>
  ),
};
const ImageWithText = ({ slice }: ImageWithTextProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="text-black ~/md:~py-16/20 md:~md/lg:~py-20/[4.5rem] lg:~lg:~py-[4.5rem]/[5.625rem]"
    >
      <Container>
        <div className="flex gap-16">
          <PrismicNextImage
            field={slice.primary.photo}
            className="hidden aspect-[321/382] h-full max-w-[50%] flex-1 rounded-[40px] object-cover sm:block lg:aspect-[380/329] desktop:aspect-[705/484] desktop:max-w-[705px]"
            fallbackAlt=""
          />
          <article
            className={`flex flex-1 flex-col justify-between ~sm/md:~gap-8/5 ${slice.variation === 'imageRight' && 'order-first'}`}
          >
            <div className="o-6">
              <PrismicRichText
                field={slice.primary.heading}
                components={component}
              />
              <PrismicRichText
                field={slice.primary.content}
                components={component}
              />
            </div>
            {slice.primary.show_button && (
              <PrismicNextLink
                field={slice.primary.button_link}
                variant="text"
                endIcon={<ArrowRightV2 />}
                className="place-self-start lg:place-self-end"
              >
                {slice.primary.button_label}
              </PrismicNextLink>
            )}
          </article>
        </div>
      </Container>
    </section>
  );
};

export default ImageWithText;
